import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import TopSubNav from "../components/TopSubNav"
import NewsArticles from "../components/NewsArticles"
import { format } from "date-fns"
import CallToActionContact from "../components/CallToActionContact"

export const query = graphql`
  {
    prismic {
      allNews_landing_pages {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            page_title
            _meta {
              uid
            }
          }
        }
      }
      allNewss(sortBy: publication_date_DESC, last: 500) {
        edges {
          node {
            _meta {
              uid
              tags
            }
            feature_image
            publication_date
            short_description
            title
          }
        }
      }
    }
  }
`

const TagColors = {
  Insights: "#0099CB",
  "Client Results": "#5DB46E",
  "@CRG": "#8CA3B5",
}

const renderComponent = data => {
  const doc = data.prismic.allNews_landing_pages.edges.slice(0, 1).pop()
  if (!doc) return null

  const articles = data.prismic.allNewss.edges
    .map(e => ({
      uid: e.node._meta.uid,
      tags:
        e.node._meta.tags &&
        e.node._meta.tags.map(t => {
          const color = !TagColors[t] ? "#8CA3B5" : TagColors[t]
          const label = t
          return { color, label }
        }),
      feature_image: e.node.feature_image && e.node.feature_image.url,
      publication_date: !e.node.publication_date
        ? undefined
        : new Date(
            e.node.publication_date.split("-")[0],
            e.node.publication_date.split("-")[1] - 1,
            e.node.publication_date.split("-")[2]
          ),
      short_description: e.node.short_description[0].text,
      title: e.node.title[0].text,
    }))
    .filter(article => Boolean(article.publication_date))
    .map(article => ({
      ...article,
      ...{
        month: format(article.publication_date, "MM"),
        year: format(article.publication_date, "yyyy"),
        monthYear: format(article.publication_date, "MMMM yyyy"),
      },
    }))

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.page_title &&
      doc.node.page_title[0] &&
      doc.node.page_title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    ""

  return (
    <Layout mainContainerStyles={{ background: "#F9F9F9" }}>
      <SEO title={seoTitle} description={seoDescription} />
      <TopSubNav
        title={doc.node.page_title[0].text}
        style={{ marginBottom: 0 }}
      />
      <NewsArticles data={articles} seoH1={doc.node.seo_h1} />
      <CallToActionContact />
    </Layout>
  )
}

export default () => {
  return (
    <>
      <StaticQuery query={query} render={withPreview(renderComponent, query)} />
    </>
  )
}
