import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { format } from "date-fns"
import ClampLines from "react-clamp-lines"

const Container = styled(Link)`
  display: flex;
  text-decoration: none;
  margin-bottom: 2rem;
  background: white;
  border: solid 1px #e3e3e3;
  border-radius: 3px;
  transform: translateY(0);
  transition: all 0.25s ease;

  &:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  }

  .feature-image {
    flex: 0 0 200px;
    height: auto;
    position: relative;

    &::before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: ${props =>
        props.imageUrl ? `url(${props.imageUrl})` : "none"};
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: cover;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    @media screen and (max-width: 480px) {
      flex: 0 0 100px;
      height: 100px;
    }
  }

  .content {
    flex: 1;
    padding: 1rem;

    h3 {
      color: #000;
      font-size: 1.2rem;
      margin: 0.25rem 0;
      font-weight: 700;
      font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
    }

    p {
      color: #777777;
      font-size: 0.9rem;
      margin: 0;
    }
  }

  .date {
    color: #87959a;
    font-family: "Montserrat";
    font-size: 0.75rem;
    margin-right: 1rem;
  }

  .tag {
    background-color: #8ca3b5;
    color: white;
    padding: 3px 12px;
    border-radius: 20px;
    font-size: 12px;
    margin-right: 0.5rem;
  }
`

const ArchivedArticleCard = ({
  uid,
  publication_date,
  tags,
  title,
  short_description,
  feature_image,
}) => {
  return (
    <Container to={`/news/${uid}`} imageUrl={feature_image}>
      {feature_image && <div className="feature-image"></div>}
      <div className="content">
        <div className="meta">
          <span className="date">
            {publication_date && format(publication_date, "MMMM dd, yyyy")}
          </span>
          <span className="tags">
            {tags &&
              tags.map(t => (
                <span
                  key={t.label}
                  className="tag"
                  style={{ backgroundColor: t.color }}
                >
                  {t.label}
                </span>
              ))}
          </span>
        </div>
        <ClampLines
          text={title}
          id={`title-${uid}`}
          lines={1}
          ellipsis="..."
          innerElement="h3"
          buttons={false}
        />
        <ClampLines
          text={short_description}
          id={`description-${uid}`}
          lines={2}
          ellipsis="..."
          innerElement="p"
          buttons={false}
        />
      </div>
    </Container>
  )
}

export default ArchivedArticleCard
