import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { format } from "date-fns"
import ClampLines from "react-clamp-lines"

const Container = styled(Link)`
  border: solid 1px #f1f1f1;
  border-radius: 3px;
  display: flex;
  text-decoration: none;
  align-self: stretch;
  flex-direction: ${props => (props.isFirst ? "row" : "column")};
  transform: translateY(0);
  transition: all 0.25s ease;
  background: white;

  &:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  }

  /* IE11 hack */
  @media all and (-ms-high-contrast: none) {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 480px) {
    grid-column: 1 / span 2;
    flex-direction: column;
  }

  .feature-image {
    flex: ${props => (props.isFirst ? "1" : "0 0 150px")};
    width: 100%;
    margin-right: 2rem;
    height: auto;
    position: relative;

    &::before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: ${props =>
        props.imageUrl ? `url(${props.imageUrl})` : "none"};
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: cover;
      border-top-right-radius: ${props => (props.isFirst ? "0px" : "3px")};
      border-top-left-radius: 3px;
      border-bottom-left-radius: ${props => (props.isFirst ? "3px" : "0px")};
    }

    @media screen and (max-width: 480px) {
      flex: 0 0 150px;

      &::before {
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0px;
      }
    }
  }

  .content {
    flex: ${props => (props.isFirst ? "1" : "1 0 auto")};
    padding: 1.5rem;

    h3 {
      color: #000;
      font-size: ${props => (props.isFirst ? "2rem" : "1.2rem")};
      margin: 0.5rem 0;
      font-weight: 700;
      font-family: "Open Sans", "Helvetica", "Arial", sans-serif;

      @media screen and (max-width: 480px) {
        font-size: 1.2rem;
      }
    }

    p {
      color: #777777;
      font-size: ${props => (props.isFirst ? "1.2rem" : "0.9rem")};
      margin: 0;

      @media screen and (max-width: 480px) {
        font-size: 0.9rem;
      }
    }
  }

  .date {
    color: #87959a;
    font-family: "Montserrat";
    font-size: 0.75rem;
    margin-right: 1rem;
  }

  .tag {
    background-color: #8ca3b5;
    color: white;
    padding: 3px 12px;
    border-radius: 20px;
    font-size: 12px;
    margin-right: 0.5rem;
  }
`

const FeaturedArticleCard = props => {
  const {
    uid,
    publication_date,
    tags,
    title,
    short_description,
    feature_image,
    isFirst,
  } = props
  return (
    <Container
      to={`/news/${uid}`}
      imageUrl={feature_image}
      className="featured-article-card"
      isFirst={isFirst}
    >
      {feature_image && <div className="feature-image"></div>}
      <div className="content">
        <div className="meta">
          <span className="date">
            {publication_date && format(publication_date, "MMMM dd, yyyy")}
          </span>
          <span className="tags">
            {tags &&
              tags.map(t => (
                <span
                  key={t.label}
                  className="tag"
                  style={{ backgroundColor: t.color }}
                >
                  {t.label}
                </span>
              ))}
          </span>
        </div>
        <ClampLines
          text={title}
          id={`title-${uid}`}
          lines={2}
          ellipsis="..."
          innerElement="h3"
          buttons={false}
        />
        <ClampLines
          text={short_description}
          id={`description-${uid}`}
          lines={isFirst ? 3 : feature_image ? 2 : 10}
          ellipsis="..."
          innerElement="p"
          buttons={false}
        />
      </div>
    </Container>
  )
}

export default FeaturedArticleCard
