import React, { useState, useMemo } from "react"
import styled from "styled-components"
import { ContentSection } from "../components/ContentSection"
import { uniqBy, orderBy, isEmpty } from "lodash"
import Select from "react-select"
import FeaturedArticleCard from "./FeaturedArticleCard"
import ArchivedArticleCard from "./ArchivedArticleCard"
import Button from "@material-ui/core/Button"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { format } from "date-fns"
import { RichText } from "prismic-reactjs"

const Filter = styled("div")`
  display: flex;
  justify-content: center;
  background: #e3e3e3;
  padding: 0.25rem 0;
  position: sticky;
  top: 164px;
  z-index: 1;

  .inner-container {
    max-width: 1200px;
    flex: 0 1 1200px;
    display: flex;
    align-items: center;
    font-size: 0.9rem;

    @media screen and (max-width: 1199px) {
      padding: 0 1rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 0.75rem;
      padding: 0 1rem;
      justify-content: space-between;
    }
  }

  .filter-label {
    margin-right: 1rem;
    color: #4d526e;
  }

  .tag-select {
    min-width: 120px;

    .select__control {
      background: none;
      border: none;
    }

    .select__value-container {
      max-width: 120px;
    }

    .select__placeholder {
      max-width: 120px;
      color: #4d526e;
    }

    .select__dropdown-indicator {
      color: #4d526e;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__indicator.select__clear-indicator {
      position: absolute !important;
      right: 22px !important;
      top: 2px !important;
    }

    @media screen and (max-width: 480px) {
      min-width: 170px;
    }
  }

  .year-select {
    min-width: 100px;

    .select__control {
      background: none;
      border: none;
    }

    .select__placeholder {
      color: #4d526e;
    }

    .select__dropdown-indicator {
      color: #4d526e;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__indicator.select__clear-indicator {
      position: absolute !important;
      right: 22px !important;
      top: 2px !important;
    }
  }
`

const LatestArticlesContainer = styled(ContentSection)`
  margin-top: 0;

  .inner-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 300px auto;
    grid-gap: 2rem;
    max-width: 900px;

    @media screen and (max-width: 720px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }

    .featured-article-card:first-child {
      grid-column: 1 / span 2;
    }
  }
`

const ArchivedArticlesContainer = styled(ContentSection)`
  .inner-container {
    border-top: solid 1px #ddd;
    max-width: 900px;
  }
`

const LoadMoreSection = styled(ContentSection)`
  .inner-container {
    max-width: 900px;
  }
`

const SeoH1Section = styled(ContentSection)`
  margin-top: 0;
  margin-bottom: 0;

  .inner-container {
    max-width: 900px;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const NewsArticles = ({ data, seoH1 }) => {
  // The raw total set of articles
  const [articles] = useState(
    orderBy(data, ["year", "month"], ["desc", "desc"])
  )

  // Filter options
  const [filterOptions, setFilterOptions] = useState({
    tag: "",
    year: "",
  })

  // Current page (very simple 'load more' pagination)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 9

  // Filters the entire (base) set of articles based on the filtered criteria
  const filteredArticles = useMemo(() => {
    const { tag, year } = filterOptions
    let result = []
    result = !tag
      ? articles
      : articles.filter(a => a.tags.filter(t => t.label === tag).length > 0)
    result = !year ? result : result.filter(a => a.year === year)
    return result
  }, [filterOptions, articles])

  // The first set of featured articles
  const featuredArticles = useMemo(
    () => filteredArticles.slice(0, itemsPerPage),
    [filteredArticles, itemsPerPage]
  )

  // All the remaining articles
  const archivedArticles = useMemo(() => filteredArticles.slice(itemsPerPage), [
    filteredArticles,
    itemsPerPage,
  ])

  const groupedArchivedArticles = useMemo(() => {
    const relevantArticles = archivedArticles.slice(
      0,
      currentPage * itemsPerPage
    )
    return uniqBy(relevantArticles, "monthYear").map(item => {
      const monthYear = item.monthYear
      const monthYearArticles = orderBy(
        relevantArticles.filter(a => a.monthYear === item.monthYear),
        ["year", "month"],
        ["desc", "desc"]
      )
      return { monthYear, monthYearArticles }
    })
  }, [archivedArticles, currentPage, itemsPerPage])

  const tagOptions = uniqBy(
    articles.reduce(
      (a, c) => [
        ...a,
        ...c.tags.map(t => ({ value: t.label, label: t.label })),
      ],
      []
    ),
    "value"
  )

  const yearOptions = uniqBy(articles, "year").map(a => ({
    value: a.year,
    label: a.year,
  }))

  function handleTagFilterChange(val) {
    if (val) {
      setFilterOptions({ ...filterOptions, ...{ tag: val.value } })
      setCurrentPage(1)
    } else {
      setFilterOptions({ ...filterOptions, ...{ tag: "" } })
      setCurrentPage(1)
    }
  }

  function handleYearFilterChange(val) {
    if (val) {
      setFilterOptions({ ...filterOptions, ...{ year: val.value } })
      setCurrentPage(1)
    } else {
      setFilterOptions({ ...filterOptions, ...{ year: "" } })
      setCurrentPage(1)
    }
  }

  function handleLoadMore() {
    setCurrentPage(currentPage + 1)
  }

  const totalPages = Math.ceil(filteredArticles.length / itemsPerPage) - 1 // minus one to account for the page of items being used for feature articles

  console.log(currentPage, totalPages)
  return (
    <>
      <Filter>
        <div className="inner-container">
          <span className="filter-label">Filter by:</span>
          <label
            id="category-select-label"
            style={{ position: "absolute", left: -9999, opacity: 0 }}
            htmlFor="react-select-2-input"
          >
            Category
          </label>
          <Select
            id="category-select"
            name="colors"
            options={tagOptions}
            className="tag-select"
            classNamePrefix="select"
            onChange={handleTagFilterChange}
            isClearable={true}
            placeholder="Category"
            aria-label="Category"
            aria-labelledby="category-select-label"
          />
          {yearOptions &&
            !(
              yearOptions.length === 1 &&
              yearOptions[0] === format(new Date(), "yyyy")
            ) && (
              <>
                <label
                  id="year-select-label"
                  style={{ position: "absolute", left: -9999, opacity: 0 }}
                  htmlFor="react-select-3-input"
                >
                  Year
                </label>
                <Select
                  name="colors"
                  options={yearOptions}
                  className="year-select"
                  classNamePrefix="select"
                  onChange={handleYearFilterChange}
                  isClearable={true}
                  placeholder="Year"
                  aria-label="Year"
                  aria-labelledby="year-select-label"
                />
              </>
            )}
        </div>
      </Filter>
      <SeoH1Section>
        <div className="inner-container">{seoH1 && RichText.render(seoH1)}</div>
      </SeoH1Section>
      <LatestArticlesContainer>
        <div className="inner-container">
          {featuredArticles.map((a, i) => (
            <FeaturedArticleCard key={a.uid} isFirst={i === 0} {...a} />
          ))}
        </div>
      </LatestArticlesContainer>
      {groupedArchivedArticles && !isEmpty(groupedArchivedArticles) && (
        <ArchivedArticlesContainer>
          <div className="inner-container">
            {groupedArchivedArticles.map(group => (
              <div>
                <p
                  style={{
                    textTransform: "capitalize",
                    fontSize: "1.25rem",
                    fontWeight: 300,
                    fontFamily: "montserrat",
                  }}
                >
                  {group.monthYear}
                </p>
                {group.monthYearArticles.map(a => (
                  <ArchivedArticleCard key={a.uid} {...a} />
                ))}
              </div>
            ))}
          </div>
        </ArchivedArticlesContainer>
      )}

      {currentPage < totalPages && (
        <LoadMoreSection>
          <Button
            size="large"
            startIcon={<ExpandMoreIcon />}
            onClick={handleLoadMore}
            style={{ color: "#0099CB", textTransform: "capitalize" }}
          >
            Load More
          </Button>
        </LoadMoreSection>
      )}
    </>
  )
}

export default NewsArticles
